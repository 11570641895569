import {useMemo} from 'react'
import {useTable, ColumnInstance, Row} from 'react-table'
import {CustomHeaderColumn} from './columns/CustomHeaderColumn'
import {CustomRow} from './columns/CustomRow'
import {useQueryResponseData, useQueryResponseLoading} from '../provider/QueryResponseProvider'
import {Branch} from '../../core/_models'
import {KTCardBody} from '../../../../../_metronic/helpers'
import { BranchListPagination } from '../components/pagination/BranchListPagination'
import { BranchListLoading } from '../components/loading/BranchListLoading'
import { branchesColumns } from './columns/_columns'

const BranchesTable = () => {
  const branches = useQueryResponseData()
  const isLoading = useQueryResponseLoading()
  const data = useMemo(() => branches, [branches])
  const columns = useMemo(() => branchesColumns, [])
  const {getTableProps, getTableBodyProps, headers, rows, prepareRow} = useTable({
    columns,
    data,
  })
 
  return (
    <KTCardBody className='py-4'>
      <div className='table-responsive'>
        <table
          id='kt_table_users'
          className='table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer'
          {...getTableProps()}
        >
          <thead>
            <tr className='text-start text-muted fw-bolder fs-7 text-uppercase gs-0'>
              {headers.map((column: ColumnInstance<Branch>) => (
                <CustomHeaderColumn key={column.id} column={column} />
              ))}
            </tr>
          </thead>
          <tbody className='text-gray-600 fw-bold' {...getTableBodyProps()}>
            {rows.length > 0 ? (
              rows.map((row: Row<Branch>, i) => {
                prepareRow(row) 
                return <CustomRow row={row} key={`row-${i}-${row.id}`} /> 
              }) 
            ) : (
              <tr>
                <td colSpan={7}>
                  <div className='d-flex text-center w-100 align-content-center justify-content-center'>
                    No matching records found 
                  </div>
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
      <BranchListPagination />
      {isLoading && <BranchListLoading />}
    </KTCardBody>
  )
}

export {BranchesTable}
