import { objectHasAttr, ROLES } from "../../../_metronic/helpers"
import { PageTitle } from "../../../_metronic/layout/core"
import { Settings } from "./core/_models"
import { SettingsDetail } from "./SettingsDetail"
import { useAuth } from "../auth"

const SettingsPage = () => {
    const {settings, currentUser} = useAuth()
    const userRole = currentUser?.role as string
    
    if(userRole === ROLES.ADMIN && objectHasAttr(settings)) {
        return (
            <SettingsDetail settings={settings as Settings}/>
        )
    }

    return null
}

export const SettingsWrapper = () => {
    return (
    <>
        <PageTitle breadcrumbs={[]}>{'Тохиргоо'}</PageTitle>
        <SettingsPage />
    </>
    )
}